import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .measurements-box-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .measurements-box-icon {
            margin-right: 1em;
            max-width: 60%;
        }

        .measurements-box-body {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .measurements-box-headline {
                color: white;
                font-size: 1.5em;
                font-weight: 600;
                text-align: left;
            }

            .measurements-box-children {
                margin-top: 1em;
                width: 100%;
            }
        }
    }
`;
