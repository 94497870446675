import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class PanelBox extends Component {
    static propTypes = {
        children: PropTypes.any,
        className: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        className: '',
    };

    render() {
        const { children, className } = this.props;
        
        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames('admin-panel-box', className)}
            >
                {children}
            </StyledComponent>
        );
    }
}
