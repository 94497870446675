import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .form-elements {
            > * {
                margin-top: 1em;
            }
        }
        
        .submit-button {
            margin-top: 2em;
        }

        .switch-footer {
            .form-element.switch {
                margin-bottom: .5em;

                .input-external-wrapper {
                    margin-bottom: 0;
                }
            }
        }

        &.layout-box {
            .form-elements {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;

                .panel-measurements-box {
                    width: 32%;
                    margin-bottom: 1em;

                    .measurements-box-children {
                        .box-body {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;

                            .box-input {
                                width: 100%;

                                .form-element {
                                    .input-external-wrapper {
                                        .input-label {
                                            color: white;
                                            font-size: 1.5em;

                                            span {
                                                font-weight: 900
                                            }
                                        }

                                        .input-internal-wrapper {
                                            border: none;
                                            height: 2.3em;

                                            input {
                                                color: rgb(216, 49, 67);
                                                height: 1.25em;
                                                width: 100%;
                                                border-radius: 0em;
                                                border-top: none;
                                                border-right: none;
                                                border-bottom: 1px solid rgb(216, 49, 67);
                                                font-size: 2em;
                                                font-weight: 600;
                                                padding: 0px;
                                                border-left: none !important;

                                                &:focus {
                                                    color: #D83143;
                                                    border-color: #D83143;
                                                }

                                                &::placeholder {
                                                    color: #979797;
                                                }
                                            
                                                &[disabled=""] {
                                                    border-bottom: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .box-units {
                                padding-left: 5%;
                                max-width: 40%;
                                color: #D83143;
                                font-size: 2em;
                                margin-bottom: .2em;
                            }
                        }
                    }
                } 

                &.form-elements-with-hidden {
                    flex-wrap: nowrap;
                    flex-direction: column;

                    .elements-container {
                        width: 100%;

                        .elements-list {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            flex-wrap: wrap;
                        }

                        .hidden-elements-trigger {
                            color: #EEEEEE;
                            font-size: .9em;
                            text-decoration: underline;
                            cursor: pointer;
                            padding: 0 2em;
                            margin-bottom: 2em;
                            display: block;

                            &:hover {
                                color: #666666;
                            }
                        }
                    }
                }              
            }
        }
        
        @media (max-width: ${variables.desktopXS}) {
            &.layout-box {
                .form-elements {
                    .panel-measurements-box {
                        width: 48%;
                    }
                }
            }
        }
        
        @media (max-width: ${variables.tabletS}) {
            &.layout-box {
                .form-elements {
                    .panel-measurements-box {
                        width: 100%;
                    }
                }
            }
        }
    `;
