import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import FormGenerator from 'Components/layoutAdmin/panel/FormGenerator';
import Button from 'Components/layoutAdmin/Button';

export default class PanelElementEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        header: PropTypes.any,
        forms: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                name: PropTypes.string.isRequired,
                validation: PropTypes.func,
            })
        ),
        details: PropTypes.arrayOf(
            PropTypes.shape({
                visible: PropTypes.bool,
                label: PropTypes.string.isRequired,
                value: PropTypes.any,
            })
        ),
        additionalBlocks: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                data: PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.string.isRequired,
                        value: PropTypes.string.isRequired,
                    })
                ),
            })
        ),
        controls: PropTypes.arrayOf(
            PropTypes.shape({
                visible: PropTypes.bool,
                title: PropTypes.string.isRequired,
                subtitle: PropTypes.string,
                buttonProps: PropTypes.object.isRequired,
            })
        ),
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
    };
    static defaultProps = {
        header: null,
        forms: [],
        details: [],
        controls: [],
        additionalBlocks: [],
        styleVersion: 1,
    };

    getDetail = ({ label, value, visible, to, href, target }) => (
        <div className="detail" key={label}>
            {to && (
                <>
                    <Link className="detail-label detail-label-link" to={to}>
                        {label}
                    </Link>
                    <span className="detail-value">
                        {value}
                    </span>
                </>
            )}
            {href && (
                <>
                    <a 
                        className="detail-label detail-label-link" 
                        href={href} 
                        target={target || '_self'}
                    >
                        {label}
                    </a>
                    <span className="detail-value">
                        {value}
                    </span>
                </>
            )}
            {!to && !href &&(
                <>
                    <span className="detail-label">
                        {label}
                    </span>
                    <span className="detail-value">
                        {value}
                    </span>
                </>
            )}
        </div>
    )

    getControl = ({ title, subtitle, buttonProps, visible }) => visible === false
        ? null
        : (
            <div className="control" key={title}>
                <div className="control-label">
                    <span className="control-title">
                        {title}
                    </span>
                    {subtitle && (
                        <span className="control-subtitle">
                            {subtitle}
                        </span>
                    )}
                </div>
                <span className="control-control">
                    <Button {...buttonProps} />
                </span>
            </div>
        )

    render() {
        const { header, forms, styleVersion } = this.props;

        let { details, controls, additionalBlocks } = this.props;
        details = details.filter(detail => detail.visible !== false);
        controls = controls.filter(control => control.visible !== false);
        additionalBlocks = additionalBlocks.filter(additionalBlock => additionalBlock.visible !== false);

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'admin-element-editor',
                    `style-version-${styleVersion}`
                )}
            >
                <div className="header-wrapper">
                    {header}
                </div>
                <div className="editor-wrapper">
                    <div className="forms-wrapper">
                        {forms.map(form => (
                            <div className="block form-wrapper" key={form.name}>
                                {form.title && (<h2 className="block-title form-title">{form.title}</h2>)}
                                <FormGenerator {...form} />
                            </div>
                        ))}
                    </div>
                    <div className="data-wrapper">
                        {details.length ?
                            <div className="block details-wrapper">
                                <h2 className="block-title details-title">Szczegóły</h2>
                                <div className="details">
                                    {details.map(this.getDetail)}
                                </div>
                            </div>
                            : null}
                        {additionalBlocks.length ?
                            additionalBlocks.map(additionalBlock => (
                                <div key={additionalBlock.key} className="block details-wrapper">
                                    <h2 className="block-title details-title">{additionalBlock.title}</h2>
                                    <div className="details">
                                        {additionalBlock.data.map(this.getDetail)}
                                    </div>
                                </div>
                            ))
                            : null}
                        {controls.length ?
                            <div className="block controls-wrapper">
                                <h2 className="block-title controls-title">Zarządzaj</h2>
                                <div className="details">
                                    {controls.map(this.getControl)}
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
